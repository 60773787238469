/** @jsx jsx */
import { graphql } from "gatsby"
import { jsx, Flex } from "theme-ui"
import Seo from "../components/seo"

export default function BlogPost({ data }) {
  const post = data.markdownRemark
  return (
    <Flex
      sx={{
        maxWidth: "700px",
        margin: "auto",
        padding: "0 1rem",
      }}
    >
      <Seo title={post.frontmatter.title} description={post.excerpt} />
      <div
        sx={{
          margin: "auto",
          overflowWrap: "break-word",
          maxWidth: "100%",
        }}
      >
        <h1>{post.frontmatter.title}</h1>
        <p>{post.frontmatter.date}</p>

        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </div>
    </Flex>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date
      }
      excerpt
    }
  }
`
